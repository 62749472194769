import { graphql } from 'gatsby'
import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ContenfulImageOverlayText from '~/components/ContenfulImageOverlayText'
import MediaBlockImageLeft from '~/components/MediaBlockImageLeft'
import MediaBlockImageRight from '~/components/MediaBlockImageRight'

const HomepagePage = ({ data }) => {
  const { blocks } = { ...data.contentfulNewPage }

  return (
    <Layout>
      <Metadata title="Homepage" />
      {blocks.map((block, index) => {
        if (block.__typename === 'ContentfulImageWithOverlayText') {
          return <ContenfulImageOverlayText key={index} order={index} data={block} />
        }
        if (block.__typename === 'ContentfulMediaBlock') {
          if (!block.imagePosition) {
            //Image on left
            return <MediaBlockImageLeft data={block} key={index + block.__typename} hasMarginY={false} />
          }
          if (block.imagePosition) {
            //Image on Right
            return <MediaBlockImageRight data={block} key={index + block.__typename} hasMarginY={false} />
          }
        }
      })}
    </Layout>
  )
}

export default HomepagePage

export const query = graphql`
  query NewPageHomepage {
    contentfulNewPage(identifier: { eq: "homepage" }) {
      title
      blocks {
        ... on ContentfulImageWithOverlayText {
          __typename
          id
          fontColor
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          subheading
          buttonTitle
          buttonLink
        }
        ... on ContentfulMediaBlock {
          __typename
          id
          title
          showTitle
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          imageAltText
          imagePosition
          showBackground
          backgroundColor
          callToAction {
            title
            slug
          }
          callToActionRight
          content {
            raw
          }
        }
      }
    }
  }
`
